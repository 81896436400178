// Import libraries
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledContainer = styled.div`
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 2rem;
    align-content: center;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
    background-color: var(--color-background-2);
    
    & .content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        justify-items: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        & .link {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-left: 10px;
            }
        }

        & .link:link,
        & .link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .link:hover,
        & .link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        } 
    }
`;


// Create component
const VerstuurdPagina = () => {
    const [formType, setFormType] = useState('');


    useEffect(() => {
        // Set form type, because window is not defined in SSR
        setFormType(window.location.search.replace('?type=', ''));
    }, []);


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Bericht Verstuurd"
                description="Bedankt voor het versturen van uw bericht, wij nemen zo spoedig mogelijk contact met u op."
            />
            <Layout>
                <StyledContainer>
                    {formType === 'index-contact-form' ? (
                        <div className="content">
                            <h1>Bericht Verstuurd</h1>

                            <p className="feature__summary">Bedankt voor het versturen van uw bericht. Wij hebben uw bericht succesvol ontvangen en zullen zo spoedig mogelijk contact met u opnemen.</p>
    
                            <Link to="/" className="link">
                                <FontAwesomeIcon icon={faLongArrowLeft} className="icon" />
                                <span>Ga Terug</span>
                            </Link>
                        </div>
                    ) : (formType === 'contact-page-contact-form') && (
                        <div className="content">
                            <h1>Bericht Verstuurd</h1>

                            <p className="feature__summary">Bedankt voor het versturen van uw bericht, wij hebben uw bericht succesvol ontvangen en zullen zo spoedig mogelijk naar u terug komen.</p>
    
                            <Link to="/contact" className="link">
                                <FontAwesomeIcon icon={faLongArrowLeft} className="icon" />
                                <span>Ga Terug</span>
                            </Link>
                        </div>
                    )}
                </StyledContainer>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default VerstuurdPagina;